import $ from 'jquery';
import Component from '../models/Component';

export default class GMap extends Component {
  static _initializers = []

  constructor (element, context) {
    super(element, context)

    

    this.mapStyle = [{
      // Riempimento etichette
      'featureType': 'all',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'saturation': '0' }, { 'color': '#666666' }, { 'lightness': '0' }],
    }, {
      'featureType': 'all',
      'elementType': 'labels.text.stroke',
      'stylers': [{ 'color': '#262626' }],
    }, {
      'featureType': 'all',
      'elementType': 'labels.icon',
      'stylers': [{ 'visibility': 'off' }],
    }, {
/*       'featureType': 'administrative',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#000000' }, { 'lightness': '100' }],
    }, {
      'featureType': 'administrative',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#000000' }, { 'lightness': '20' }, { 'weight': 1.2 }],
    }, { */
      'featureType': 'landscape',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#212121' }],
    }, {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#181818' }],
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#313131' }],
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#313131' }],
    }, {
      'featureType': 'road.arterial',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#313131' }],
    }, {
/*       'featureType': 'road.arterial',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#0000' }],
    }, { */
      'featureType': 'road.local',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#313131' }],
    }, {
/*       'featureType': 'road.local',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#000000' }, { 'lightness': 80 }, { 'weight': 0.2 }],
     }, { */
      'featureType': 'transit',
      'elementType': 'geometry',
      'stylers': [{ 'visibility': 'off' }],
    }, {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#000000' }],
    }]

    // Add the initialization of this GMap instance to the list of all
    // gMaps to be initialized
    GMap._initializers.push(() => {
      this.initGoogleMaps()
    })

    $(() => {
      this.lat = parseFloat(this.getData('lat'));
      this.lng = parseFloat(this.getData('lng'));
      this.zoom = parseFloat(this.getData('zoom'), 14);
      this.url = this.getData('url');
      this.loadGoogleMaps()
    })
  }

  // This script will be executed after the googleapis js is loaded
  initGoogleMaps () {
    this.center = new google.maps.LatLng(this.lat, this.lng)
    this.map = new google.maps.Map(this.element.get(0), {
      zoom: this.zoom,
      minZoom: 4,
      center: this.center,
      scrollwheel: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      rotateControl: false,
      styles: this.mapStyle,
    })

    // this.pinMaps = window.location.origin+'/wp-content/uploads/2024/01/address.svg'
    // console.log(this.pinMaps)

    this.marker = new google.maps.Marker({
      position: this.center,
      map: this.map,
      //icon: this.pinMaps,
      
    })
    this.marker.addListener("click", () => {
      this.onMarkerClick();
    })
    this.element.parent().removeClass('loading')
  }

  onMarkerClick() {
    window.open(this.url, '_blank');
  }

  loadGoogleMaps () {
    // Append google apis script if not already present
    if (!window.initGoogleMaps) {
      window.initGoogleMaps = () => {
        GMap.initAllGoogleMaps()
      }
      var scriptSrc = 'https://maps.googleapis.com/maps/api/js?key=' + window._gTheme_.google_maps_api_key + '&callback=initGoogleMaps'
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = scriptSrc
      document.body.appendChild(script)
    }
  }

  static initAllGoogleMaps () {
    GMap._initializers.forEach(function (initializer) {
      initializer()
    })
  }
}
