import $ from 'jquery';
import Component from '../models/Component';

export default class TimeLine extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {
      let testo = this.element.find('.testo-pagina-relativa');
      let link = this.element.find('.arrow-pagina-relativa');
      testo.append(link);
    });
  }
}
