import $ from 'jquery';
import {
  gsapSingleton,
  scrollTriggerSingleton
} from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);


// let pages = $('.menu-item > .sub-menu .menu-item-has-children .icon');
// // //PAGINE TERZO LIVELLO
// $(pages).on('click', function (e) {
//   e.stopPropagation(); 
//   console.log(e);

//   const li = $(e.currentTarget).parent();


//   // Rimuovere la classe 'submenu-open' da tutte le voci del menu esclusa quella corrente
//   // $('#menu-main-menu li').not(li).removeClass('submenu-open');
  
//   // Alternare la classe 'submenu-open' sulla voce del menu cliccata
//   li.toggleClass('submenu-open');

// //   let subpages = $(this).children('.sub-menu');
// //   $('.menu-item > .sub-menu .menu-item-has-children .sub-menu').not(subpages).removeClass('submenu-open');
// //   $('.menu-item-has-children .icon').not($(this).children('.icon')).removeClass('submenu-open');
// //   $('.menu-item-has-children').not(this).removeClass('submenu-open');

// //   $(subpages).toggleClass('submenu-open');
// //   $(this).children('.icon').toggleClass('submenu-open');
// //   $(this).toggleClass('submenu-open');
// });



let hamburgher = $('.hamburger');

$(hamburgher).on('click', function () {
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#menu-items').hide();
    $('html, body').removeClass('open-menu no-scroll');
  } else {
    $(this).addClass('is-active');
    $('#menu-items').show();
    $('html, body').addClass('open-menu no-scroll');

    let titoliHeader = document.querySelectorAll('.menu-item > a');


    titoliHeader.forEach(element => {
      gsap.from(element, {
        y: '110%',
        delay: 0.3,
        opacity: 0,
        stagger: {
          each: 0.1
        },
      });
    });

  }
})



$('#menu-main-menu li.menu-item-has-children').not('.chi_siamo, .cosa_facciamo').on('click', function(e) {
  const target = $(e.target);

  // Se il target del click è un link (tag <a>) e l'attributo href non è "#", permetti la navigazione
  if (target.is('a') && target.attr('href') !== '#') {
    return;
  }

  e.preventDefault();
  e.stopPropagation();

  const li = $(this);
  const parentLi = li.parents('.submenu-open');

  console.log(li, parentLi);

  // Rimuovere la classe 'submenu-open' da tutte le voci del menu esclusa quella corrente e il suo genitore
  $('#menu-main-menu li').not(li.add(parentLi)).removeClass('submenu-open');
  
  // Alternare la classe 'submenu-open' sulla voce del menu cliccata
  li.toggleClass('submenu-open');
  
  return false;
});




$('#menu-main-menu .chi_siamo .icon, #menu-main-menu .cosa_facciamo .icon').on('click', function(e) {
  e.preventDefault();
  e.stopPropagation();


  const li = $(this).parent();
  const parentLi = li.parents('.submenu-open');

  console.log(li, parentLi);

  // Rimuovere la classe 'submenu-open' da tutte le voci del menu esclusa quella corrente e il suo genitore
  $('#menu-main-menu li').not(li.add(parentLi)).removeClass('submenu-open');
  
  // Alternare la classe 'submenu-open' sulla voce del menu cliccata
  li.toggleClass('submenu-open');
  
  return false;
});
