import SwiperSlider from "../components/abstract/SwiperSlider";
import Swiper from 'swiper';
import $ from 'jquery'

export default class Slider extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {
      var swiper = new Swiper(element, {
        slidesPerView: '2.8',
        spaceBetween: 80,
        centeredSlides: true,
        loop: true,
      });
    });
  }
}