import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, SplitText);

export function gsapSingleton() {
 return gsap;
}

export function scrollTriggerSingleton() {
  return ScrollTrigger;
}

export function splitTextSingleton() {
  return SplitText;
}